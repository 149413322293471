import L from 'leaflet';

const PIN_RED = require("assets/img/pin-red.png")
const PIN_USER = require("assets/img/user.png")

const iconPin = new L.Icon({
    iconUrl: PIN_RED,
    iconRetinaUrl: PIN_RED,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(30, 37),
    
});

const iconUser = new L.Icon({
    iconUrl: PIN_USER,
    iconRetinaUrl: PIN_USER,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(35, 35),
    
});


export { iconUser, iconPin };