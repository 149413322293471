import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import video from "../../assets/media/moon2.mp4"

// core components

function LandingPageHeader({ mobile }) {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  let headerStyle = {}
  if (mobile){
    headerStyle = {
      minHeight: 240, 
      maxHeight: 240
    }
  }
  return (
    <>
      <div className="page-header page-header-small"  style={headerStyle}>
        { mobile && (
          <div
          className="page-header-image"
          style={{
            // height: "40px",
            backgroundImage: "url(" + require("assets/img/moon-yoga-teacher-training.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        ) }
        { !mobile && (<div style={{ marignBottom: "-3px"}} ref={pageHeader} >
          <video autoPlay={true} muted={true} loop={true} id="video-background" style={{width: "100%"}}>
            <source src={video} type="video/mp4" />
          </video>
        </div> )}
        <div className="content-center moon-logo-container">
          <Container>
            <div className="text-center">
              <img
                alt="Nurturing Minds and Bodies through Yoga since 2013"
                className="moon-logo"
                src={require("assets/logo.png")}
              ></img>
            <p className="moon-logo-text">Nurturing Minds and Bodies through Yoga since 2013</p>
              
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
