import React, { useState, useEffect } from 'react';
import { MapContainer, Marker, Popup, TileLayer, FeatureGroup } from 'react-leaflet'
import "leaflet/dist/leaflet.css";

import {iconPin, iconUser} from './LIcon'

const LeafletMap = ({markers, mapCenter, active, showUserLocation}) => {
  
  const [activeMarker, setActiveMarker] = useState(null)
  const [position, setPosition] = useState(null);
  
  const mapRef = React.useRef(null);
  const featGroupRef = React.useRef(null);
  const markerRefs = React.useRef([]);

  //   user location
  useEffect(() => {
    if (!showUserLocation || !mapRef.current || !featGroupRef.current) return;
    const map = mapRef.current;
    map.locate().on("locationfound", function (e) {
        setPosition(e.latlng);
    });
  }, [mapRef.current, featGroupRef.current]);

  // once user location set resize to fit both   
  useEffect(() => {
    if (!mapRef.current || !position || !featGroupRef.current) return;
    const map = mapRef.current;
    map.fitBounds(featGroupRef.current.getBounds())
  }, [position, featGroupRef.current]);

  // initial resize
  useEffect(() => {
    if (!mapRef.current || !featGroupRef.current) return;
    const map = mapRef.current;
    map.fitBounds(featGroupRef.current.getBounds())
  }, [featGroupRef.current]);


  React.useEffect(() => {
    if (!activeMarker || !mapRef.current) return;
    markerRefs.current[activeMarker.id].openPopup()
    mapRef.current.flyTo([activeMarker.lat, activeMarker.lng], 18);
  }, [activeMarker]);
  
  

  React.useEffect(() => {
    setActiveMarker(markers.find(m => m.id === active))
  }, [markers, active])
  
  const tileLayer = {
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  }

  const center = [mapCenter['lat'], mapCenter['lng']];
  
  return (
    <MapContainer ref={mapRef} center={center} zoom={18} scrollWheelZoom={false} style={{ height: '600px', width: '100wh' }}    >
        <TileLayer {...tileLayer} />
        <FeatureGroup ref={featGroupRef}>
            {/* studio marker */}
            {
                markers.map((marker, index) => (
                    <Marker 
                        key={marker.id} 
                        ref={el => markerRefs.current[marker.id] = el}
                        position={[parseFloat(marker.lat), parseFloat(marker.lng)]} 
                        eventHandlers={{
                            click: () => {
                              setActiveMarker(marker);
                            },
                          }}
                        icon={iconPin}
                    >
                    {/* Popup */}
                        {activeMarker && activeMarker.id === marker.id && (
                            <Popup>
                                <div style={{padding: '10px'}}>
                                    <h5>{activeMarker && activeMarker.name}</h5>
                                    <p>{activeMarker && activeMarker.desc}</p>
                                    {activeMarker && <a href={"/yoga-in-hanoi/"+activeMarker.slug} className="btn_1">more details</a>}
                                </div>
                            </Popup>
                        )}                        
                    </Marker>))
            }
                
            {/* user location */}
            { position === null ? null : <Marker position={position} icon={iconUser} /> }
        </FeatureGroup>

    </MapContainer>
  );
};


export default LeafletMap