import React from "react";
import { useParams } from 'react-router';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Helmet} from "react-helmet";
import HeaderButtons from "components/HeaderButtons.js";

import LeafletMap  from "../components/LefletMaps";

// reactstrap components
import {
  Button,
  Container,
  UncontrolledTooltip
} from "reactstrap";


// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import data from "../assets/media/yoga_studios.json"


function YogaHanoiStudioPage({props}) {
  const [studio, setStudio] = React.useState(null);
  const [mapCenter, setMapCenter] = React.useState(null)
  
  const splat = useParams();
  React.useEffect(() => {
    if (Object.keys(splat).includes('studio') && splat['studio'].length > 0) {
        const slug = splat['studio']
        setStudio(data.find(s => s.slug.endsWith(slug)))
    }
    window.scrollTo(0, 0);
  }, [splat])

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  
  return (
    <>
    { studio &&
    <Helmet>
        <title>{studio.title}</title>
        <meta name="description" content={studio?.description} />
        <meta property="og:title" content={studio.title} />
        <meta property="og:description" content={studio?.description} />
        <script type="application/ld+json">{JSON.stringify(studio.json_ld)}</script>
    </Helmet>
    }
      <ExamplesNavbar />
      <div className="wrapper">
        <ProfilePageHeader />
        <div className="section">
          <Container>
            <HeaderButtons></HeaderButtons>
            </Container>
      { studio && 
      
            <div className="container-fluid full-height">
              <Breadcrumb>
                  <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                  <BreadcrumbItem><a href="/yoga-in-hanoi">Yoga in Hanoi</a></BreadcrumbItem>
                  <BreadcrumbItem active>{studio.data.displayName.text}</BreadcrumbItem>
              </Breadcrumb>
              {/* <div className="row row-height">
                <div className="col-lg-12" style={{}}>
                  <h4>{studio.data.displayName.text}</h4>
                  <p>{studio.data?.shortFormattedAddress}</p>
                  <p>phone: {studio.data?.internationalPhoneNumber}</p>
                  <p>{studio.data?.websiteUrl}</p>
                </div>
              </div> */}
              <div className="row row-height">
                <div className="col-lg-6 map-right" style={{marginTop: '40px', padding: 0, height: '600px'}}>
                  {/* <GoogleMaps 
                    markers={ [ {id: studio.data.id, name: studio.data.displayName.text, desc: studio.data.shortFormattedAddress, lat: studio.data.location.latitude, lng: studio.data.location.longitude, slug: studio.slug } ]}
                    mapCenter={{lat: studio.data.location.latitude, lng: studio.data.location.longitude}}
                    active={false}
                    markerCallback={(id) => {}}
                  /> */}
                  <LeafletMap 
                    markers={ [ {id: studio.data.id, name: studio.data.displayName.text, desc: studio.data.shortFormattedAddress, lat: studio.data.location.latitude, lng: studio.data.location.longitude, slug: studio.slug } ]}
                    mapCenter={{lat: studio.data.location.latitude, lng: studio.data.location.longitude}}
                    active={false}
                    showUserLocation={true}
                    markerCallback={(id) => {}}
                  />
                </div>
                <div className="col-lg-6 content-left business-list" style={{padding: "10px 30px 10px", margin: '0px' }}>
                  <h4>{studio.data.displayName.text}</h4>
                  <p>{studio.data?.shortFormattedAddress}</p>
                  <p>phone: {studio.data?.internationalPhoneNumber}</p>
                  <p>{studio.data?.websiteUrl}</p>
                  <div className="article" style={{ marginTop: 50 }} dangerouslySetInnerHTML={{__html: studio.content}} />
                </div>
            </div>
          </div>
      }
                
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default YogaHanoiStudioPage;
