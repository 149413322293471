import {
    Button
  } from "reactstrap";
  
const HeaderButtons = () => (
  <div className="button-container">
    <Button className="btn-round" color="info" size="lg">
      <a href="https://www.facebook.com/moonyogaflow/events" style={{ color: "white"}} target="_blank">
        Follow
      </a>

    </Button>
    <Button
      className="btn-round btn-icon"
      color="default"
      size="lg"
    >
      <a href="https://www.instagram.com/madzia.moonyoga/" style={{ color: "white"}} target="_blank">
        <i className="fab fa-instagram"></i>
      </a>
      
    </Button>
  </div>
)
export default HeaderButtons;