import React from "react";
import { useParams } from 'react-router';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Helmet} from "react-helmet";
import HeaderButtons from "components/HeaderButtons.js";

// reactstrap components
import {
  Button,
  Container,
} from "reactstrap";

import { Link } from "react-router-dom";


// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import data from "../assets/media/ashtanga2.json"

function ManualPage({props}) {
  const [article, setArticle] = React.useState(null);
  const [articleTitles, setArticleTitles] = React.useState(null);
  
  const splat = useParams();
  React.useEffect(() => {
    if (Object.keys(splat).includes('articleSlug') && splat['articleSlug'].length > 0) {
        const slug = splat['articleSlug']
        // console.log(slug)
        // console.log(data.find(article => article.slug.endsWith(slug)))
        setArticle(data.find(article => article.slug.endsWith(slug)))
    }
    else{
        setArticleTitles(data.map((article, index) => (
            <div key={index}>
              <Button
                  className="btn-link"
                  style={{ marginTop: 2, marginBottom: 2}}
                  size="lg"
                  to={ article.slug }
                  tag={Link}
                  >
                  - {article.title}
              </Button>
              <p style={{ marginLeft: 10 }}>{article.json_ld['description']}</p>
      
            </div>
          )
        ))
    }
    window.scrollTo(0, 0);
  }, [splat])

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  
  return (
    <>
    { article &&
    <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.json_ld['description']} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.json_ld['description']} />
        <script type="application/ld+json">{JSON.stringify(article.json_ld)}</script>
    </Helmet>
    }
    { !article &&
    <Helmet>
        <title>Ashtanga Yoga Teacher Training, 200 hours</title>
        <meta name="description" content="Explore the ancient practice of Ashtanga Yoga through our comprehensive 200-hour Yoga Teacher Training manual. This page offers a glimpse into the rich teachings and philosophy behind this traditional yoga style, including asana sequences, pranayama techniques, and insights into the eight limbs of yoga. Immerse yourself in the authentic Ashtanga yoga tradition and deepen your understanding of this transformative practice." />
    </Helmet>
    }
      <ExamplesNavbar />
      <div className="wrapper">
        <ProfilePageHeader />
        <div className="section">
          <Container>
            <HeaderButtons></HeaderButtons>
            <div style={{ marginTop: 40 }}>

            {
                article ? (
                    <div>
                        <Breadcrumb>
                            <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                            <BreadcrumbItem><a href="/yoga-ashtanga-200-hours-ytt">Ashtanga Yoga YTT 200 hours</a></BreadcrumbItem>
                            <BreadcrumbItem active>{article.title}</BreadcrumbItem>
                        </Breadcrumb>
                        <div style={{ marginTop: 40 }} className="article" dangerouslySetInnerHTML={{__html: article.content}} />
                    </div>
                    )
                : articleTitles && (
                    <div>
                        <Breadcrumb>
                            <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                            <BreadcrumbItem active>Ashtanga Yoga YTT 200 hours</BreadcrumbItem>
                        </Breadcrumb>
    
                    <h3 style={{ marginTop: 60, marginBottom: 40}}>Ashtanga Yoga YTT 200 hours</h3>
                    <p>The ancient practice of yoga has its roots deeply embedded in the rich cultural tapestry of India, dating back thousands of years to the Indus-Sarasvati civilization. This holistic discipline, with its emphasis on harmonizing the mind, body, and spirit, has endured the test of time, evolving into various styles and forms over the centuries. From the codification of its principles in Patanjali's revered Yoga Sutras to the emergence of modern-day practices like Hatha, Ashtanga, Vinyasa, Yin, Restorative, Iyengar, and Kundalini, yoga has become a global phenomenon, transcending boundaries and cultures.                    </p>
                    <p>Ashtanga Yoga, in particular, stands as a dynamic and transformative practice, rooted in the traditional lineage while offering a challenging and invigorating experience for practitioners of all levels. This comprehensive teacher training program delves into the intricacies of Ashtanga Yoga, equipping you with the knowledge, skills, and techniques to embark on a journey of personal growth and spiritual awakening.</p>
                    <div style={{ marginTop: 40, marginBottom: 40 }}                >
                        <Button
                            className="btn-round"
                            color="info" size="lg"
                            to="https://facebook.com/moonyogaflow"
                            tag={Link}
                        >
                            Get in touch
                        </Button>

                    </div>
                    <h4>What will you learn:</h4>
                    {articleTitles}
                    </div>
                    )
                }
            </div>

                </Container>
                </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default ManualPage;
