import React from "react";
import { useParams } from 'react-router';
import {Helmet} from "react-helmet";
import ReactList from 'react-list';

import HeaderButtons from "components/HeaderButtons.js";
import LeafletMap  from "../components/LefletMaps";


// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import data from "../assets/media/yoga_studios.json"


const Studio = ({business, active, setActive}) => {
    return  (
      <a className="strip_list" onClick={() => setActive(business.data.id)} style={{ cursor: 'pointer'}}>
        <div className="row">
          <div className="col-md-10">
            <div className="desc">
              <h4 style={ active === business.data.id ? {color: "#e04f67", marginTop: "10px"} : {marginTop: "10px"}}>{business.data.displayName.text}</h4>
              <div className="type">
                {business.data.shortFormattedAddress}
              </div>
              <div className="location">{business.data.internationalPhoneNumber}</div>
              <a href={"/yoga-in-hanoi/"+business.slug} className="btn_1">more details</a>
            </div>
          </div>
        </div>
        {/* End row*/}
      </a>
    )
  }
  

function YogaHanoiPage({props}) {
  const [article, setArticle] = React.useState(null);
  const [mapCenter, setMapCenter] = React.useState({ lat: 21.052,  lng: 105.83636 })
  const [active, setActive] = React.useState(null)
  const [studios, setStudios] = React.useState(data)
  const [filtered, setFiltered] = React.useState(data)
  
  
  // const splat = useParams();
  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [splat])

  React.useEffect(() => {
    if (!active){
      return
    }
    let business = studios.find((b) => b.data.id === active)
    setMapCenter({lat: business.data.location.latitude, lng: business.data.location.longitude})

  }, [active])

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  
  const onFilter = (e) => {
    let value = e.target.value
    let filtered = studios.filter((b) => b.data.displayName.text.toLowerCase().includes(value.toLowerCase()))
    setActive(null)
    setFiltered(filtered)
  }

  const renderStudio = (index, key) => {
    return <Studio business={filtered[index]} key={key} active={active} setActive={setActive}/>
  }

  
  return (
    <>
    { article &&
    <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.description} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.description} />
        <script type="application/ld+json">{JSON.stringify(article.schema_markup)}</script>
    </Helmet>
    }
    { !article &&
    <Helmet>
        <title>Resources and articles</title>
        <meta name="description" content="Explore our comprehensive collection of yoga articles covering various styles, poses, meditation techniques, and wellness tips. Deepen your practice with expert guidance from experienced instructors and learn about the numerous benefits of yoga for mind, body, and soul." />
    </Helmet>
    }
      <ExamplesNavbar />
      <div className="wrapper">
        <ProfilePageHeader />
        <div className="section">
          <Container>
            <HeaderButtons></HeaderButtons>
            </Container>
            <div className="container-fluid full-height">
      <div className="row row-height">
        <div className="col-lg-6 map-right" style={{marginTop: '40px', padding: 0}}>
          <LeafletMap 
            markers={filtered.map(b => { return {id: b.data.id, name: b.data.displayName.text, desc: b.data.shortFormattedAddress, lat: b.data.location.latitude, lng: b.data.location.longitude, slug: b.slug }})} 
            mapCenter={mapCenter}
            active={active}
            showUserLocation={false}
            markerCallback={(id) => {setActive(id)}}
          />
        </div>
        <div className="col-lg-6 content-left business-list" style={{padding: "50px 30px 10px", margin: '0px' }}>
        <h3 className="text-center">Yoga & Pilates Studios in Hanoi, Vietnam</h3>
        <div className="form-group" style={{ marginLeft: '20px', marginRight: '20px'}}>
            <input 
              type="text" 
              className="form-control" 
              placeholder="&#x1F50D;" 
              style={{filter: "grayscale(100%)"}} 
              onChange={onFilter} 
            />
        </div>
        <div className="business-list-container">

            <ReactList itemRenderer={renderStudio}
            length={filtered.length}
            pageSize={3}
            type='simple' />

          {/* {filtered && filtered.sort((a, b) => a.data.userRatingCount < b.data.userRatingCount).map((business, index) => (
            <Studio business={business} key={business.data.id} active={active} setActive={setActive}/>
          ))} */}

        </div>
          
 
        </div>
        {/* End content-left*/}
        
      </div>
      {/* End row*/}
    </div>
    
                
                </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default YogaHanoiPage;
