import React from "react";
import { SpinnerDiamond } from 'spinners-react';
import { FacebookProvider, Page } from 'react-facebook';

import { Link } from "react-router-dom";


// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function LandingPage() {
  
  const [mobile, setMobile] = React.useState(window.innerWidth <= 500);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  } 

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    window.addEventListener('resize', handleWindowSizeChange);
    return function cleanup() {
      window.removeEventListener('resize', handleWindowSizeChange);
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <LandingPageHeader mobile={mobile} /> 
        <div className="section section-about-us">
          <Container>
          <Row>
              <Col className="ml-auto mr-auto" md="12">
                <h3 className="title index-title">MoonYoga Hanoi</h3>
                  <p>
                    We are gathering people around yoga, organizing workshops, retreats and teacher training in Hanoi and across Vietnam since 2013.
                    Cooperating with number of studios and retreat venues we strive to deliver the best teachers, enviroment and yoga experience. 
                    <br/><br/>
                    If you are you looking upcoming <a href="/yoga-teacher-training">Yoga Teacher Trainings</a> or Workshops please check out facebook events page at <a href="https://www.facebook.com/moonyogaflow/events" target="_blank">Events</a>.
                    <br/><br/>
                    For regular classes please check <a href="/yoga-in-hanoi">Yoga in Hanoi</a> section of the website.
                  </p>  
              </Col>
          </Row>
          <div className="separator section-team" style={{ marginBottom: 30 }}></div>
          <div className="section-story-overview">
          <Row id="updatesEvents">
              <Col className="ml-auto mr-auto text-right" md="6">
              <h3>Updates and Events</h3>
                <p>
                  Embark on a transformative journey with our Yoga Teacher Trainings, held 2-3 times annually. 
                  Explore upcoming sessions and workshops on our Facebook event page: <br />
                  <a href="https://www.facebook.com/moonyogaflow/events" target="_blank">MoonYoga - coming events</a>
                 
                </p>
              </Col>
              <Col md="5">
                <FacebookProvider appId="383057384633661">
                  <Page href="https://www.facebook.com/moonyogaflow" tabs="timeline"  width={500} height={640} smallHeader={false} adaptContainerWidth={true}  />
                </FacebookProvider>

              </Col>
            </Row>
            </div>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left image-container-home"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/moon1.jpg") + ")"
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      "Yoga is the golden key that unlocks the door to peace, tranquility, and joy." <br></br>
                      <br></br>
                      <small>B.K.S. Iyengar</small>
                    </p>
                  </div>
                  <div
                    className="image-container image-container-home" 
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/moon3.jpg") + ")"
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right image-container-home"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/moon2.jpg") + ")"
                    }}
                  ></div>
                  <h3 style={{ marginTop: 20 }}>
                    Yoga Teacher Training in Vietnam
                  </h3>
                  <p>
                  Embark on a transformative journey and become a certified Yoga Teacher in the captivating city of Hanoi, Vietnam. Our comprehensive training programs offer two convenient options to suit your lifestyle. The Weekend Course spans over 7-8 weekends, providing flexibility for those with weekday commitments. Alternatively, the Intensive Course is an immersive experience lasting approximately 4 weeks, ideal for those seeking an accelerated path to certification.
                  </p><p>
                  Prepare to immerse yourself in a transformative journey that will deepen your understanding of yoga and unveil your true potential as a teacher.
                  </p>
                  <Button
                    className="btn-round pull-right home-ytt-btn"
                    color="info" size="lg"
                    to="/yoga-teacher-training"
                    tag={Link}
                  >
                    Yoga Teacher Trainings
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="section section-team text-center">
          <Container>
            <h2 className="title" style={{ paddingBottom: 20 }}>Yoga in Hanoi</h2>
            <div className="team">
              <Row>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/om-logo.png")}
                    ></img>
                    <h4 className="title">NoMad OM Factory Tây Hồ</h4>
                    <p className="category text-info">Yoga Studio</p>
                    <p className="description">36A, Ngõ 67 Tô Ngọc Vân, Hà Nội</p>
                    <p className="description">
                    Founded in 2017, NoMad Om Factory Tay Ho has become a beloved yoga studio in Hanoi, Vietnam. The studio has garnered a loyal following from both locals and visitors alike. You will find drop in classes, workshops, as well as teacher training programs.
                    </p>
                    <Button
                      className="btn-round"
                      style={{ marginTop: 20 }}                
                      color="info" size="lg"
                      to="/yoga-in-hanoi/nomad-om-factory-tay-ho"
                      tag={Link}
                    >
                      More details
                    </Button>
                    
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/the-fintess-village-logo.png")}
                    ></img>
                    <h4 className="title">The Fitness Village</h4>
                    <p className="category text-info">Gym & Yoga Studio</p>
                    <p className="description">68 Ngh. 310/50 Đ. Nghi Tàm, Tứ Liên</p>
                    <p className="description">
                    The Fitness Village stands out as a beacon of wellness, blending high-quality fitness and yoga offerings with the embrace of nature. As You delved into this unique workout space, You discovered a space where health, community, and tranquility converge.
                    </p>
                    <Button
                      className="btn-round"
                      style={{ marginTop: 20 }}                
                      color="info" size="lg"
                      to="/yoga-in-hanoi/the-fitness-village"
                      tag={Link}
                    >
                      More details
                    </Button>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/zenith-logo.png")}
                    ></img>
                    <h4 className="title">Zenith</h4>
                    <p className="category text-info">Pillates & Yoga Studio</p>
                    <p className="description">Nhà số 2, Ngõ 371 Kim Mã, Ba Đình</p>
                    <p className="description">
                      Whether you are a seasoned yogi or a beginner embarking on your yoga journey, Zenith promises a transformative experience. With a commitment to a community of like-minded individuals, this studio fosters a sense of belonging and camaraderie among its practitioners.
                    </p>
                    <Button
                      className="btn-round"
                      style={{ marginTop: 20 }}                
                      color="info" size="lg"
                      to="/yoga-in-hanoi/zenith-yoga-kim-ma"
                      tag={Link}
                    >
                      More details
                    </Button>
                  </div>
                  <p className="pull-right">
                    <a href="/yoga-in-hanoi">see more</a>
                  </p>
                </Col>
              </Row>
              
            </div>
          </Container>
        </div>

        <DefaultFooter />
      </div>
    </>
  );
}

export default LandingPage;
