import React from "react";

import HeaderButtons from "components/HeaderButtons.js";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import { Link } from "react-router-dom";

function YTTPage() {
  const [pills, setPills] = React.useState("2");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <ProfilePageHeader />
        <div className="section">
          <Container style={{ paddingBottom: 60 }}>
          <HeaderButtons></HeaderButtons>
          

            <h3 style={{ marginTop: 50, marginBottom: 40}}>MoonYoga's Immersive Training Experience</h3>
    <p>MoonYoga offers a transformative Yoga Teacher Training experience that spans 8-9 consecutive weekends (Friday to Sunday) with approximately 20-22 hours of training per week. The course features a diverse team of experienced instructors covering topics such as anatomy, philosophy, and more. Classes are conducted in English, with Vietnamese translation available.</p>

    <p>MoonYoga organizes two Yoga Teacher Training programs annually, one in spring and one in autumn. Stay tuned to their Facebook page for the latest schedule and updates.</p>

    <p>Embark on this life-changing journey and unlock your full potential as a yoga teacher and seeker of truth. Join MoonYoga's Yoga Teacher Training 200 hours and experience the transformative power of yoga.</p>

            <Button
                className="btn-round"
                style={{ marginTop: 40 }}                
                color="info" size="lg"
                to="/yoga-ashtanga-200-hours-ytt"
                tag={Link}
              >
                MoonYoga - Ashtanga 200 Hours YTT Program
              </Button>

            <h3 style={{ marginTop: 60}}>Embark on a Transformative Journey with Yoga Teacher Training 200 Hours</h3>

    <p>Yoga Teacher Training is not just about learning how to teach yoga postures; it's a comprehensive experience that offers numerous benefits, including:</p>

    <h6 style={{ marginTop: 30}}>Deepen your Practice</h6>
    <p style={{ marginTop: 10, marginLeft: 40}}>The teacher training course helps to understand the true goals and purpose of Yoga. You will learn to figure out how you can be a better human being, friend, parent, and family member. Teacher training teaches you finer skills on how to be a good listener and find working solutions to everyday problems.</p>

    <h6 style={{ marginTop: 10}}>Explore Yoga Philosophy</h6>
    <p style={{ marginTop: 10, marginLeft: 40}}>Delve into the ancient wisdom of yoga philosophy, gaining a profound understanding of the principles and teachings that have guided yogis for centuries. You'll explore concepts such as the Yoga Sutras, the Bhagavad Gita, and the eight limbs of yoga, which will enrich your practice and provide a solid foundation for your teaching journey.</p>

    <h6 style={{ marginTop: 10}}>Become a Yoga Professional</h6>
    <p style={{ marginTop: 10, marginLeft: 40}}>Upon successful completion of the 200-hour Yoga Teacher Training program, you will earn a globally recognized certification from Yoga Alliance. This credential opens doors to exciting career opportunities in the yoga industry, allowing you to teach in studios, health clubs, retreats, and even start your own yoga business.</p>

    <h6 style={{ marginTop: 10}}>Connecting with Yourself</h6>
    <p style={{ marginTop: 10, marginLeft: 40}}>The yoga teacher training journey is a profound opportunity for self-discovery and personal growth. Through meditation, self-inquiry, and introspection, you'll gain a deeper understanding of your true nature, strengths, and purpose. This newfound self-awareness will empower you to live a more authentic and fulfilling life.</p>

    <h6 style={{ marginTop: 10}}>Get a Boost in Confidence</h6>
    <p style={{ marginTop: 10, marginLeft: 40}}>As you immerse yourself in the training, you'll develop a strong sense of self-confidence and self-assurance. The supportive environment, coupled with the acquisition of new skills and knowledge, will help you overcome self-doubt and embrace your potential as a yoga teacher and leader.</p>

    <h6 style={{ marginTop: 10}}>Learn Valuable Skills</h6>
    <p style={{ marginTop: 10, marginLeft: 40}}>In addition to mastering yoga postures and sequences, you'll acquire a wealth of essential skills during the training. These include effective communication, public speaking, teaching methodologies, anatomy and physiology, and the ability to create inclusive and safe spaces for your students.</p>

    <h6 style={{ marginTop: 10}}>Career Boost</h6>
    <p style={{ marginTop: 10, marginLeft: 40}}>Completing a 200-hour Yoga Teacher Training can open up a world of professional opportunities beyond teaching yoga classes. Many graduates go on to pursue careers in wellness coaching, retreat planning, writing, and even entrepreneurship within the yoga and wellness industries.</p>

    <div style={{ marginTop: 40, marginBottom: 40 }}                >
    <Button
        className="btn-round"
        color="info" size="lg"
        to="https://facebook.com/moonyogaflow"
        tag={Link}
      >
        Get in touch
      </Button>

    </div>

    
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default YTTPage;
