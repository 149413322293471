import React from "react";
import { useParams } from 'react-router';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Helmet} from "react-helmet";

// reactstrap components
import {
  Button,
  Container,
} from "reactstrap";

import { Link } from "react-router-dom";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

import data from "../assets/media/resources.json"

import HeaderButtons from "components/HeaderButtons.js";

function ResourcesPage({props}) {
  const [article, setArticle] = React.useState(null);
  const [articleTitles, setArticleTitles] = React.useState(null);
  
  const splat = useParams();
  React.useEffect(() => {
    if (Object.keys(splat).includes('articleSlug') && splat['articleSlug'].length > 0) {
        const slug = splat['articleSlug']
        // console.log(slug)
        // console.log(data.find(article => article.slug.endsWith(slug)))
        setArticle(data.find(article => article.slug.endsWith(slug)))
    }
    else{
        setArticleTitles(data.map((article, index) => (
            <div key={index}>
              <Button
                  className="btn-link"
                  style={{ marginTop: 2, marginBottom: 2}}
                  size="lg"
                  to={ article.slug }
                  tag={Link}
                  >
                  - {article.title}
              </Button>
              <p style={{ marginLeft: 10 }}>{article.description}</p>
      
            </div>
          )
        ))
    }
    window.scrollTo(0, 0);
  }, [splat])

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  
  return (
    <>
    { article &&
    <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.description} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.description} />
        <script type="application/ld+json">{JSON.stringify(article.schema_markup)}</script>
    </Helmet>
    }
    { !article &&
    <Helmet>
        <title>Resources and articles</title>
        <meta name="description" content="Explore our comprehensive collection of yoga articles covering various styles, poses, meditation techniques, and wellness tips. Deepen your practice with expert guidance from experienced instructors and learn about the numerous benefits of yoga for mind, body, and soul." />
    </Helmet>
    }
      <ExamplesNavbar />
      <div className="wrapper">
        <ProfilePageHeader />
        <div className="section">
          <Container>
            <HeaderButtons></HeaderButtons>
            <div style={{ marginTop: 40 }}>

            {
                article ? (
                    <div>
                        <Breadcrumb>
                            <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                            <BreadcrumbItem><a href="/resources">Resources</a></BreadcrumbItem>
                            <BreadcrumbItem active>{article.title}</BreadcrumbItem>
                        </Breadcrumb>
                        <div style={{ marginTop: 40 }} className="article" dangerouslySetInnerHTML={{__html: article.content}} />
                    </div>
                    )
                : articleTitles && (
                    <div>
                        <Breadcrumb>
                            <BreadcrumbItem><a href="/">Home</a></BreadcrumbItem>
                            <BreadcrumbItem active>Resources</BreadcrumbItem>
                        </Breadcrumb>
    
                        <h3 style={{ marginTop: 60, marginBottom: 40}}>Resources</h3>
                        <p>Explore our comprehensive collection of yoga articles covering various styles, poses, meditation techniques, and wellness tips. Deepen your practice with expert guidance from experienced instructors and learn about the numerous benefits of yoga for mind, body, and soul.</p>

                        {articleTitles}
                    </div>
                    )
                }
            </div>

                </Container>
                </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default ResourcesPage;
